/* eslint-disable no-multi-assign */
/* eslint-disable no-continue */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-loop-func */
import moment from 'moment'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useMediaQuery } from 'react-responsive'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextFieldProps,
} from '@mui/material'
import { navigate } from 'gatsby'
import { noLimitParams } from 'manage-tritag/utils/string'
import { ObjectID } from 'bson'
import _ from 'lodash'

import {
  Competition,
  useGetCompetitionsQuery,
} from 'manage-tritag/services/api/endpoints/competitions'
import EnhancedTable from 'manage-tritag/components/table/enhanced-table'
import {
  Round,
  RoundCategory,
  useAddDrawBuilderMutation,
} from 'manage-tritag/services/api/endpoints/draw-builder'
import { getFinalsSystem } from 'manage-tritag/utils/finals-systems'
import DrawBuilderSyncFutureDatesModal from 'manage-tritag/components/modal/draw_builder_future_dates_modal'
import { Match } from 'manage-tritag/services/api/endpoints/matches'
import DrawBuilderEditMatchModal from 'manage-tritag/components/modal/draw_builder_edit_match_modal'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import {
  FormContent,
  SecondGrid,
  StyledButton,
  StyledButtonDiv,
  StyledGridContainer,
  StyledTextField,
} from './styles'

interface TimeSlot {
  id: number
  value: moment.Moment | null
}

const headCells: HeadCell[] = [
  {
    id: 'round',
    numeric: false,
    disablePadding: false,
    label: 'Round',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Home team',
  },
  {
    id: 'subMaster',
    numeric: false,
    disablePadding: false,
    label: 'Away team',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'Date / time',
  },
  {
    id: 'venue',
    numeric: false,
    disablePadding: false,
    label: 'Venue',
  },
  {
    id: 'fieldNo',
    numeric: false,
    disablePadding: false,
    label: 'Field no',
  },
]

const bodyCells = [
  'round',
  'homeTeam',
  'awayTeam',
  'startDate',
  'venue',
  'fieldNo',
]

const generateRounds = (
  numberOfRegularRounds: number,
  finalsSystemName: string,
  startDate: moment.Moment,
): Round[] => {
  const rounds = []
  const finalsSystem = getFinalsSystem(finalsSystemName)
  const numberOfFinalRounds = finalsSystem?.rounds || 0

  for (let i = 0; i < numberOfRegularRounds + numberOfFinalRounds; i++) {
    if (i < numberOfRegularRounds) {
      rounds.push({
        roundCategory: RoundCategory.REGULAR_SEASON,
        roundNumber: i + 1,
        date: startDate.add(7, 'days').format('YYYY-MM-DD'),
      })
    } else {
      rounds.push({
        roundCategory: RoundCategory.FINALS,
        roundNumber: i - numberOfRegularRounds + 1,
        date: startDate.add(7, 'days').format('YYYY-MM-DD'),
      })
    }
  }

  return rounds
}

const generateMatches = (
  roundType: string,
  competition: Competition | undefined,
  gamesPerTimeSlot: number,
  rounds: Round[],
  timeSlots: TimeSlot[],
): any[] => {
  const teamIds = competition?.teams?.map(team => team._id) || []
  const generalRounds = rounds.filter(
    round => round.roundCategory === RoundCategory.REGULAR_SEASON,
  )
  const finalRounds = rounds.filter(
    round => round.roundCategory === RoundCategory.FINALS,
  )

  let roundRobin = []
  if (roundType === 'ROUND_ROBIN') {
    roundRobin = generateMatchPairingsForRoundRobin(
      generalRounds.length,
      teamIds,
    )
  } else {
    roundRobin = generateMatchPairingsForStandard(generalRounds.length, teamIds)
  }

  const matches = []
  for (let i = 0; i < generalRounds.length; i++) {
    const matchesInRound = roundRobin[i].length
    let roundRobinIndex = 0
    let matchesCreatedInRound = 0
    for (let j = 0; j < timeSlots.length; j++) {
      for (let k = 0; k < gamesPerTimeSlot; k++) {
        if (matchesCreatedInRound >= matchesInRound) {
          break
        }

        const teamPairing = roundRobin[i][roundRobinIndex]

        matches.push({
          _id: new ObjectID(),
          round: {
            category: generalRounds[i].roundCategory,
            number: generalRounds[i].roundNumber,
          },
          fieldNo: k + 1,
          time: moment(
            `${generalRounds[i].date} ${timeSlots[j].value?.format('HH:mm')}`,
          ).format('DD/MM/YYYY h:mm A'),
          homeTeam: competition?.teams?.find(
            team => team._id === teamPairing[0],
          ),
          awayTeam:
            teamPairing[1] !== 'BYE'
              ? competition?.teams?.find(team => team._id === teamPairing[1])
              : null,
          matchDisplayName: '',
          bye: teamPairing[1] === 'BYE',
          tbc: false,
          venue: competition?.venue ?? null,
        })

        roundRobinIndex++
        matchesCreatedInRound++
      }
    }
  }

  const finalMatches = getFinalsSystem(competition?.finalsSystem)?.matches ?? []
  for (let i = 0; i < finalRounds.length; i++) {
    const matchesInRound = finalMatches.filter(
      match => match.round === finalRounds[i].roundNumber,
    )
    let matchesIndex = 0
    for (let j = 0; j < timeSlots.length; j++) {
      for (let k = 0; k < gamesPerTimeSlot; k++) {
        if (matchesIndex >= matchesInRound.length) {
          break
        }

        matches.push({
          _id: new ObjectID(),
          round: {
            category: finalRounds[i].roundCategory,
            number: finalRounds[i].roundNumber,
          },
          fieldNo: k + 1,
          time: moment(
            `${finalRounds[i].date} ${timeSlots[j].value?.format('HH:mm')}`,
          ).format('DD/MM/YYYY h:mm A'),
          homeTeam: null,
          awayTeam: null,
          matchDisplayName: matchesInRound[matchesIndex].name,
          bye: false,
          tbc: true,
          venue: competition?.venue ?? null,
        })

        matchesIndex++
      }
    }
  }

  return matches
}

const generateMatchPairingsForStandard = (numRounds: number, teams: any[]) => {
  const DUMMY = -1
  const rounds: any[] = []
  let numTeams = teams.length
  teams = teams.slice()

  // workaround for odd team numbers
  if (numTeams % 2 === 1) {
    teams.push(DUMMY)
    numTeams += 1
  }

  for (let j = 0; j <= numRounds; j += 1) {
    rounds[j] = []
    for (let i = 0; i < numTeams / 2; i += 1) {
      const o = numTeams - 1 - i
      if (teams[i] !== DUMMY && teams[o] !== DUMMY) {
        const isHome = i === 0 && j % 2 === 1
        rounds[j].push([
          isHome ? teams[o] : teams[i],
          isHome ? teams[i] : teams[o],
        ])
      } else {
        rounds[j].push([teams[o] === DUMMY ? teams[i] : teams[o], 'BYE'])
      }
    }
    teams.splice(1, 0, teams.pop())
  }

  return rounds
}

const generateMatchPairingsForRoundRobin = (
  numRounds: number,
  teams: any[],
) => {
  const rounds: any[] = []
  const numTeams = teams.length
  teams = teams.slice()

  for (let j = 0; j <= numRounds; j += 1) {
    rounds[j] = []
    for (let i = 0; i < numTeams; i += 1) {
      for (let o = i + 1; o < numTeams; o += 1) {
        const isHome = i !== 0 && o % 2 === 1
        rounds[j].push([
          isHome ? teams[o] : teams[i],
          isHome ? teams[i] : teams[o],
        ])
      }
    }
    rounds[j] = _.shuffle(rounds[j])
  }

  return rounds
}

const getGamesPerRoundForRoundRobin = (
  gamesPerTimeSlot: number,
  teamCount: number,
  eachWay: boolean = false,
) => {
  const eachWayMultiplier = eachWay ? 1 : 2

  return Math.ceil(
    (teamCount * (teamCount - 1)) / eachWayMultiplier / gamesPerTimeSlot,
  )
}

const DrawBuilderCreateForm = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 898px)` })
  const [createDrawBuilder] = useAddDrawBuilderMutation()
  const { data: competitions, isLoading: competitionLoading } =
    useGetCompetitionsQuery({ ...noLimitParams, currentSeason: true })
  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues: {
      competition: '',
      roundType: 'STANDARD',
      gamesPerTimeSlot: '',
    },
  })
  const watchAllFields = watch()
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([
    { id: 1, value: null },
    { id: 2, value: null },
    { id: 3, value: null },
  ])

  const [rounds, setRounds] = useState<any[]>([])
  const [matches, setMatches] = useState<any[]>([])
  const [tab, setTab] = useState(0)
  const [competition, setCompetition] = useState<Competition | null>(null)
  const [roundType, setRoundType] = useState<string>('STANDARD')
  const [gamesPerTimeSlot, setGamesPerTimeSlot] = useState<number>(0)
  const [roundRobinStartTime, setRoundRobinStartTime] = useState<Date>(
    new Date(),
  )
  const [roundRobinMatchDuration, setRoundRobinMatchDuration] =
    useState<number>(15)
  const [editRoundActive, setEditRoundActive] = useState(false)
  const [editRound, setEditRound] = useState<Round | null>(null)
  const [editMatchActive, setEditMatchActive] = useState(false)
  const [editMatch, setEditMatch] = useState<Match | null>(null)
  const [matchResetRequired, setMatchResetRequired] = useState<boolean>(false)

  useEffect(() => {
    if (
      tab === 0 &&
      watchAllFields.competition &&
      parseInt(watchAllFields.gamesPerTimeSlot) &&
      watchAllFields.roundType === 'ROUND_ROBIN' &&
      roundRobinStartTime &&
      roundRobinMatchDuration > 0
    ) {
      const competition = competitions?.find(
        comp => comp._id === watchAllFields.competition,
      )

      const newTimeSlots = []

      const xGamesPerRound = getGamesPerRoundForRoundRobin(
        parseInt(watchAllFields.gamesPerTimeSlot),
        competition?.teams?.length ?? 0,
      )
      for (let i = 0; i < xGamesPerRound; i++) {
        const timeSlotTime = moment(roundRobinStartTime).add(
          i * roundRobinMatchDuration,
          'minutes',
        )
        newTimeSlots.push({ id: i, value: timeSlotTime })
      }

      setTimeSlots(newTimeSlots)
    }
  }, [
    competitions,
    roundRobinStartTime,
    roundRobinMatchDuration,
    tab,
    watchAllFields.competition,
    watchAllFields.gamesPerTimeSlot,
    watchAllFields.roundType,
  ])

  const resetRounds = (competition: Competition) => {
    const roundArray = generateRounds(
      competition!.numberOfRounds || 0,
      competition!.finalsSystem,
      moment(competition!.startDate),
    )
    setRounds(roundArray)
    setMatchResetRequired(true)
  }

  const resetMatches = () => {
    const matchArray = generateMatches(
      roundType,
      competition!,
      gamesPerTimeSlot,
      rounds,
      timeSlots,
    )
    setMatches(matchArray)
  }

  const getNextTimeSlotId = (): number => {
    let next = 1
    const timeSlotsIds = timeSlots.map((ts: TimeSlot) => ts.id)

    while (timeSlotsIds.includes(next)) {
      next++
    }

    return next
  }

  const handleAddMore = () => {
    setTimeSlots([...timeSlots, { id: getNextTimeSlotId(), value: null }])
  }

  const handleTimeSlotChange = (date: moment.Moment | null, index: number) => {
    const items = [...timeSlots]
    const item = items[index]
    item.value = date && date.isValid() ? date : null
    items[index] = item
    setTimeSlots(items)
  }

  const roundDateChange = (date: moment.Moment, index: number) => {
    if (date.isValid()) {
      const items = [...rounds]
      const item = items[index]
      item.date = date
      items[index] = item
      setRounds(items)
      setMatchResetRequired(true)

      if (index !== rounds.length - 1) {
        setEditRound(item)
        setEditRoundActive(true)
      }
    }
  }

  const handleFutureDatesSync = () => {
    const startIndex = rounds.findIndex(round => {
      return (
        round.roundCategory === editRound?.roundCategory &&
        round.roundNumber === editRound?.roundNumber
      )
    })
    const items = [...rounds]
    for (let i = startIndex; i < items.length; i++) {
      const item = {
        ...items[i],
        date: moment(items[startIndex].date)
          .add((i - startIndex) * 7, 'days')
          .format('YYYY-MM-DD'),
      }
      items[i] = item
    }
    setRounds(items)
    setMatchResetRequired(true)
    setEditRoundActive(false)
    setEditRound(null)
  }

  const handleCancel = () => {
    if (tab === 2) {
      setTab(1)
    } else if (tab === 1) {
      setTab(0)
    } else if (tab === 0) {
      navigate('/draw-builder')
    }
  }

  const handleMatchRowClick = (
    event: React.MouseEvent<unknown>,
    id: number | string,
  ) => {
    const match = matches.find(m => m._id === id)
    setEditMatch(match)
    setEditMatchActive(true)
  }

  const handleEditMatch = (match: Match) => {
    const items = [...matches]
    const index = matches.findIndex(m => m._id === match._id)
    const item = match
    items[index] = item
    setMatches(items)

    setEditMatchActive(false)
    setEditMatch(null)
  }

  const hasEnoughMatchSlotsAvailable = (
    roundType: string,
    competition: Competition,
    gamesPerTimeSlot: number,
    timeslotCount: number,
  ): boolean => {
    if (roundType === 'ROUND_ROBIN') return true

    const roundMatchSlotsAvailable = timeslotCount * gamesPerTimeSlot
    const teamCount = (competition?.teams ?? []).length

    if (teamCount / 2 <= roundMatchSlotsAvailable) {
      const finalsSystem = getFinalsSystem(competition?.finalsSystem)
      return (finalsSystem?.minMatchesPerRound ?? 2) <= roundMatchSlotsAvailable
    }

    return false
  }

  const notifyInsufficientMatchCapacity = () => {
    toast(
      'Insufficient match capacity. Add more timeslots, increase games played per time slot or change competition finals system.',
      {
        type: 'error',
        toastId: 'insufficient-match-capacity',
      },
    )
  }

  const onSubmit: SubmitHandler<any> = async () => {
    if (tab === 0) {
      const competition = competitions?.find(
        comp => comp._id === watchAllFields.competition,
      )

      if (competition) {
        setCompetition(competition)
      }

      if (watchAllFields.gamesPerTimeSlot) {
        setGamesPerTimeSlot(parseInt(watchAllFields.gamesPerTimeSlot))
      }

      if (watchAllFields.roundType) {
        setRoundType(watchAllFields.roundType)
      }

      const timeSlotArr = timeSlots
        .filter((ts: any) => ts.value !== null)
        .sort((tsA, tsB) => tsA.value!.unix() - tsB.value!.unix())
      setTimeSlots(timeSlotArr)

      if (competition) {
        if (
          !hasEnoughMatchSlotsAvailable(
            watchAllFields.roundType,
            competition,
            parseInt(watchAllFields.gamesPerTimeSlot),
            timeSlotArr.length,
          )
        ) {
          notifyInsufficientMatchCapacity()
          return
        }
      } else {
        toast.error('Selected competition not found.')
        return
      }

      if (rounds.length === 0) {
        resetRounds(competition)
      }

      setTab(1)
    }

    if (tab === 1) {
      if (matches.length === 0 || matchResetRequired) {
        resetMatches()
      }

      setTab(2)
    }

    if (tab === 2) {
      create(false)
    }
  }

  const create = async (publish: boolean) => {
    await createDrawBuilder({
      competition: competition?._id,
      gamesPerSlot: gamesPerTimeSlot,
      roundType,
      timeSlots: timeSlots.map(ts => ts.value?.format('HH:mm')),
      rounds: rounds.map((round: Round) => {
        return { ...round }
      }),
      matches: matches.map(({ _id, ...match }: any) => {
        return {
          ...match,
          time: moment(match.time, 'DD/MM/YYYY h:mm A').toISOString(),
          venue: match?.venue?._id ?? null,
        }
      }),
      publish,
    })

    reset()
    toast.success(
      publish
        ? 'Matches published successfully'
        : 'Draw builder saved succesfully.',
    )

    navigate('/draw-builder')
  }

  const onPublish = async () => {
    create(true)
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      {tab === 0 && (
        <StyledGridContainer container justifyContent="space-between">
          <SecondGrid item xs={12} sm={12} md={12}>
            <Typography
              sx={{
                fontWeight: 'bold',
                color: '#008174',
                marginBottom: '0.2rem',
                marginTop: isMobile ? '2rem' : '0',
              }}
            >
              Draw settings
            </Typography>

            <FormContent>
              <FormControl variant="filled" size="small" sx={{ width: '100%' }}>
                <InputLabel id="competition-select-filled-label">
                  Competition *
                </InputLabel>
                <Controller
                  name="competition"
                  control={control}
                  render={({ field }) => (
                    <Select
                      inputProps={{ tabIndex: 1 }}
                      labelId="competition-select-filled-label"
                      id="competition-select-filled"
                      disableUnderline
                      {...field}
                      required
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {!competitionLoading &&
                        competitions &&
                        competitions.map(competition => (
                          <MenuItem
                            key={competition._id}
                            value={competition._id}
                          >
                            {competition.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </FormControl>
              <FormControl variant="filled" size="small" sx={{ width: '100%' }}>
                <InputLabel id="round-type-select-filled-label">
                  Round Type *
                </InputLabel>
                <Controller
                  name="roundType"
                  control={control}
                  render={({ field }) => (
                    <Select
                      inputProps={{ tabIndex: 2 }}
                      labelId="round-type-select-filled-label"
                      id="round-type-select-filled"
                      disableUnderline
                      {...field}
                      required
                    >
                      <MenuItem value="STANDARD">Standard</MenuItem>
                      <MenuItem value="ROUND_ROBIN">Round Robin</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
              <Controller
                name="gamesPerTimeSlot"
                control={control}
                render={({ field }) => (
                  <StyledTextField
                    InputProps={{
                      disableUnderline: true,
                      inputProps: {
                        tabIndex: 3,
                      },
                    }}
                    id="filled-basic"
                    label="Games played per time slot"
                    variant="filled"
                    size="small"
                    {...field}
                    required
                  />
                )}
              />

              {!isMobile && <Box sx={{ width: '100%' }} />}
            </FormContent>
          </SecondGrid>
          {watchAllFields.roundType === 'STANDARD' && (
            <SecondGrid item xs={12} sm={12} md={4}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color: '#008174',
                  marginBottom: '0.2rem',
                  marginTop: isMobile ? '4rem' : '4rem',
                }}
              >
                Time slots
              </Typography>
              {timeSlots.map((time: any, i: number) => (
                <FormContent key={`ts-${i}`}>
                  <TimePicker
                    label="Time"
                    value={time?.value}
                    onChange={(date: any) => handleTimeSlotChange(date, i)}
                    renderInput={(field: TextFieldProps) => (
                      <StyledTextField
                        {...field}
                        InputLabelProps={{
                          ...field.InputLabelProps,
                          shrink: true,
                        }}
                        InputProps={{
                          ...field.InputProps,
                          disableUnderline: true,
                          tabIndex: 3 + (i + 1),
                        }}
                        size="small"
                        sx={{
                          ...field.sx,
                          marginBottom: '-0.5rem',
                        }}
                        variant="filled"
                      />
                    )}
                    inputFormat="hh:mm A"
                  />
                </FormContent>
              ))}

              <StyledButton
                variant="contained"
                color="primary"
                onClick={handleAddMore}
                sx={{ marginTop: '2rem' }}
              >
                Add more
              </StyledButton>
            </SecondGrid>
          )}

          {watchAllFields.roundType === 'ROUND_ROBIN' && (
            <SecondGrid item xs={12} sm={12} md={8}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color: '#008174',
                  marginBottom: '0.2rem',
                  marginTop: isMobile ? '4rem' : '4rem',
                }}
              >
                Time slots
              </Typography>
              <FormContent>
                <TimePicker
                  label="Round robin start time"
                  value={roundRobinStartTime}
                  onChange={(date: any) => setRoundRobinStartTime(date)}
                  renderInput={(field: TextFieldProps) => (
                    <StyledTextField
                      {...field}
                      InputLabelProps={{
                        ...field.InputLabelProps,
                        shrink: true,
                      }}
                      InputProps={{
                        ...field.InputProps,
                        disableUnderline: true,
                        tabIndex: 4,
                      }}
                      size="small"
                      sx={{
                        ...field.sx,
                        marginBottom: '-0.5rem',
                      }}
                      variant="filled"
                    />
                  )}
                  inputFormat="hh:mm A"
                />
                <StyledTextField
                  InputProps={{
                    disableUnderline: true,
                    inputProps: {
                      tabIndex: 5,
                    },
                  }}
                  id="filled-basic"
                  label="Round robin match duration"
                  variant="filled"
                  size="small"
                  type="number"
                  onChange={(event: any) =>
                    setRoundRobinMatchDuration(event.target.value)
                  }
                  value={roundRobinMatchDuration}
                  required
                />
              </FormContent>
              <ul>
                {timeSlots
                  .filter((time: any) => {
                    return time.value !== null
                  })
                  .map((time: any, i: number) => (
                    <li key={`ts-${i}`}>
                      {moment(time.value).format('h:mm A')}
                    </li>
                  ))}
              </ul>
            </SecondGrid>
          )}
        </StyledGridContainer>
      )}
      {tab === 1 && (
        <Box>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#008174',
              marginBottom: '0.2rem',
              marginTop: isMobile ? '2rem' : '0',
            }}
          >
            Draw settings
          </Typography>

          <StyledGridContainer container spacing={2}>
            {rounds.map((round: any, i: number) => (
              <Grid item key={`round${i}`} md={4}>
                <FormContent>
                  <DatePicker
                    label={`${round.roundCategory} ${round.roundNumber}`}
                    value={round.date}
                    onChange={(date: any) => roundDateChange(date, i)}
                    renderInput={(field: TextFieldProps) => (
                      <StyledTextField
                        {...field}
                        InputLabelProps={{
                          ...field.InputLabelProps,
                          shrink: true,
                        }}
                        InputProps={{
                          ...field.InputProps,
                          disableUnderline: true,
                        }}
                        size="small"
                        sx={{
                          ...field.sx,
                          marginBottom: '-0.5rem',
                        }}
                        variant="filled"
                      />
                    )}
                    inputFormat="DD/MM/YYYY"
                  />
                </FormContent>
              </Grid>
            ))}
            {editRoundActive && editRound && (
              <DrawBuilderSyncFutureDatesModal
                setModal={setEditRoundActive}
                round={editRound as Round}
                handleFutureDatesSync={handleFutureDatesSync}
              />
            )}
          </StyledGridContainer>
        </Box>
      )}

      {tab === 2 && (
        <>
          <Box display="flex" justifyContent="space-between" mb={3}>
            <Typography
              sx={{
                fontWeight: 'bold',
                color: '#008174',
                marginBottom: '0.2rem',
                marginTop: isMobile ? '2rem' : '0',
              }}
            >
              Draw settings
            </Typography>

            <StyledButton
              variant="contained"
              color="primary"
              onClick={onPublish}
            >
              Publish matches
            </StyledButton>
          </Box>
          <EnhancedTable
            tableTitle="Draw Builder"
            uniqueIdentifier="ttr_id"
            rows={matches.map((match, index) => ({
              id: index,
              ...match,
              round: `${match.round.category} ${match.round.number}`,
              homeTeam: match.tbc ? 'TBC' : match.homeTeam.name,
              awayTeam: match.tbc
                ? 'TBC'
                : match.bye
                ? '-'
                : match.awayTeam.name,
              startDate: match.time,
              venue: match?.venue?.name ?? null,
            }))}
            headCells={headCells}
            bodyCells={bodyCells}
            handleRowClick={handleMatchRowClick}
          />
          {editMatchActive && editMatch && (
            <DrawBuilderEditMatchModal
              competition={competition}
              setModal={setEditMatchActive}
              match={editMatch as Match}
              handleEditMatch={handleEditMatch}
            />
          )}
        </>
      )}
      <StyledButtonDiv>
        <div>
          {tab === 1 && (
            <StyledButton
              variant="outlined"
              onClick={() => resetRounds(competition!)}
              style={{
                border: '2px solid #008174',
                color: '#000',
              }}
            >
              Reset rounds
            </StyledButton>
          )}
          {tab === 2 && (
            <StyledButton
              variant="outlined"
              onClick={() => resetMatches()}
              style={{
                border: '2px solid #008174',
                color: '#000',
              }}
            >
              Reset matches
            </StyledButton>
          )}
        </div>

        <div>
          <StyledButton
            variant="outlined"
            onClick={handleCancel}
            style={{
              border: '2px solid #008174',
              color: '#000',
              marginRight: '1.5rem',
            }}
          >
            CANCEL
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            type="submit"
            style={{ width: '83px' }}
          >
            {tab === 0 || tab === 1 ? 'Next' : 'Save'}
          </StyledButton>
        </div>
      </StyledButtonDiv>
    </Box>
  )
}

export default DrawBuilderCreateForm
